import { useEffect, useMemo, useState } from 'react';
import { MapRef } from 'react-map-gl';

import Supercluster from 'supercluster';

import { Organisation } from '@/lib/api-dots';

/**
 * Map point that can be either a single location or a group of locations (cluster)
 */
export interface PointFeature {
  type: 'Feature';
  properties: {
    cluster?: boolean;
    cluster_id?: number;
    point_count?: number;
    point_count_abbreviated?: string;
    organisation?: Organisation;
    extKey?: string;
    name?: string;
  };
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
}

/**
 * Hook to handle grouping nearby points on the map
 */
export const useMapClusters = (organisations: Organisation[], mapRef: React.RefObject<MapRef>) => {
  const [points, setPoints] = useState<PointFeature[]>([]);

  // Create points manager
  const supercluster = useMemo(() => {
    if (!organisations) return null;

    // Convert organizations to map points
    const points: PointFeature[] = organisations.map((org) => ({
      type: 'Feature',
      properties: {
        organisation: org,
        name: org.Company,
        extKey: org.ExtKey,
      },
      geometry: {
        type: 'Point',
        coordinates: [org.Longitude, org.Latitude],
      },
    }));

    // Setup point grouping
    const cluster = new Supercluster({
      radius: 45,
      maxZoom: 13,
      map: (props) => ({
        organisation: props.organisation,
        extKey: props.extKey,
      }),
    });

    cluster.load(points);
    return cluster;
  }, [organisations]);

  // Update points based on map view
  const updateClusters = () => {
    if (!supercluster || !mapRef?.current) return;

    const map = mapRef.current.getMap();
    const bounds = map.getBounds();
    if (!bounds) return;

    const clusters = supercluster.getClusters(
      [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
      Math.floor(map.getZoom()),
    );

    setPoints(clusters as PointFeature[]);
  };

  // Update the clusters as well when the organisations change
  useEffect(() => {
    updateClusters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisations]);

  return { points, supercluster, updateClusters };
};
