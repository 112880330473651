import React from 'react';
import { useTranslation } from 'react-i18next';

import MailIcon from '@images/Mail.svg';
import PhoneIcon from '@images/Phone.svg';
import WebsiteIcon from '@images/Website.svg';

import { Label } from '@/components/Label';
import { Organisation } from '@/lib/api-dots';
import {
  callOrganisationNumber,
  formatCityName,
  formatPhoneNumber,
  generateOrganisationLabels,
  openOrganisationWebsite,
  sendOrganisationEmail,
} from '@/utils/organisationUtils';

import { PrimaryButton } from '../Button';
import {
  ButtonGroup,
  ButtonsContainer,
  CardWrapper,
  LabelsContainer,
  MemberAdress,
  MemberAdressContainer,
  MemberContainer,
  MemberContentButton,
  MemberTitle,
} from './styles';

export interface OrganisationCardProps {
  organisation: Organisation;
  onClick?: () => void;
  isMemberSelected?: boolean;
}

/**
 * @description List card component for displaying one organisation information
 *  with buttons to call, email and visit the organisation website and labels
 */

export const ListCard: React.FC<OrganisationCardProps> = ({
  organisation,
  onClick,
  isMemberSelected,
}) => {
  const { t } = useTranslation('common');

  return (
    <CardWrapper id={organisation.ExtKey} $isMemberSelected={isMemberSelected ?? false}>
      <MemberContainer>
        <MemberContentButton onClick={onClick}>
          <MemberTitle>{organisation.Company}</MemberTitle>
          <MemberAdressContainer>
            <MemberAdress>
              <div>
                {organisation.Street} {organisation.Housenumber}
              </div>
              <div>
                {organisation.ZipCode} {formatCityName(organisation.City)}
              </div>
            </MemberAdress>
            <MemberAdress>
              <div>{formatPhoneNumber(organisation.Tel)}</div>
              <div>{organisation['E-mail1']}</div>
            </MemberAdress>
          </MemberAdressContainer>
        </MemberContentButton>
        {/* Render each label as a separate Label component */}
        <LabelsContainer>
          {generateOrganisationLabels(organisation, t).map((label, index) => (
            <Label key={index} label={label} />
          ))}
        </LabelsContainer>
        <ButtonsContainer>
          <ButtonGroup>
            {/* Conditionally render call button */}
            {organisation.Tel && (
              <PrimaryButton
                icon={<PhoneIcon />}
                label={t('call')}
                onClick={() => callOrganisationNumber(organisation.Tel)}
              />
            )}
            {/* Conditionally render email button */}
            {organisation['E-mail1'] && (
              <PrimaryButton
                icon={<MailIcon />}
                label={t('email')}
                onClick={() => sendOrganisationEmail(organisation['E-mail1'])}
              />
            )}
          </ButtonGroup>
          {/* Conditionally render website button */}
          {organisation.Internet && (
            <PrimaryButton
              icon={<WebsiteIcon />}
              label={t('website')}
              onClick={() => openOrganisationWebsite(organisation.Internet)}
            />
          )}
        </ButtonsContainer>
      </MemberContainer>
    </CardWrapper>
  );
};
