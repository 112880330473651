import styled from 'styled-components';

interface CardWrapperProps {
  $isMemberSelected: boolean;
}

export const CardWrapper = styled.div<CardWrapperProps>`
  margin-top: 1rem;
  margin-inline: 0.5rem;
  border-bottom: ${(props) => (props.$isMemberSelected ? '0' : '1px solid')};
  border-color: ${(props) => props.theme.colors.border};
`;

export const MemberTitle = styled.span`
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};
  color: ${(props) => props.theme.colors.typographyHeading};
`;

export const MemberContainer = styled.div`
  padding: 0.5rem;
  margin-bottom: 1rem;


  &:hover, &:focus-within, &:active {
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.colors.backgroundAccent};
    color: ${(props) => props.theme.colors.primary};

    ${MemberTitle} {
      color: ${(props) => props.theme.colors.primary};
    }
`;

export const MemberContentButton = styled.button`
  all: unset;
  cursor: pointer;
`;

export const MemberAdressContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const MemberAdress = styled.span`
  font-size: ${(props) => props.theme.typography4.fontSize};
  font-weight: ${(props) => props.theme.typography4.fontWeight};
  line-height: ${(props) => props.theme.typography4.lineHeight};
  color: ${(props) => props.theme.colors.typographyBody};
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-block: 1.5rem;
`;

export const ButtonsContainer = styled.div``;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
`;
