import { useCallback, useEffect, useRef, useState } from 'react';
import {
  default as MapBox,
  GeolocateControl,
  LngLatBoundsLike,
  MapRef,
  NavigationControl,
} from 'react-map-gl';

import { useMemberContext } from '@/context/useMemberContext';
import { useIsMobileOrTablet } from '@/hooks/useIsMobileOrTablet';
import { PointFeature, useMapClusters } from '@/hooks/useMapClusters';
import { useOrganisations } from '@/hooks/useOrganisations';

import { ClusterMarker } from './ClusterMarker';
import { MapMarker } from './MapMarker';
import { MapContainer } from './styles';

//Default map configuration
const INITIAL_VIEWPORT = {
  latitude: 52.2,
  longitude: 5.3,
  zoom: 7,
};
const DEFAULT_CENTER = { lat: 52.2, lng: 5.3 };

/**
 * Main map component showing organizations
 */

export const Map = () => {
  const isSmallScreen = useIsMobileOrTablet();
  const mapRef = useRef<MapRef>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);

  const {
    isSearchPopupVisible,
    search,
    coordinates,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
    member,
    setMember,
  } = useMemberContext();
  const { organisations } = useOrganisations(
    search,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  );

  const { points, supercluster, updateClusters } = useMapClusters(organisations || [], mapRef);

  const hasFilter = useCallback(() => {
    return Boolean(search) || qualityLabel || governanceCode || socialCouncilWork;
  }, [search, qualityLabel, governanceCode, socialCouncilWork]);

  /**
   * Initializes map and triggers initial cluster update
   */
  const handleMapLoad = () => {
    if (!mapRef.current || mapLoaded) return;
    setMapLoaded(true);
    setTimeout(() => updateClusters(), 100);

    if (member) {
      mapRef.current?.jumpTo({
        center: [member.Longitude, member.Latitude],
        zoom: 15,
      });
    } else if (hasFilter()) {
      mapRef.current?.fitBounds(getBoxCoordinates(), { padding: 100, maxZoom: 10 });
    }
  };

  /**
   * Updates viewport state and clusters when map moves
   */
  const handleViewportChange = () => {
    if (!mapRef.current) return;
    const map = mapRef.current.getMap();
    setViewport({
      latitude: map.getCenter().lat,
      longitude: map.getCenter().lng,
      zoom: map.getZoom(),
    });
    updateClusters();
  };

  /**
   * Handles cluster marker clicks by zooming to expand cluster
   * @param cluster - The clicked cluster feature
   */
  const handleClusterClick = (cluster: PointFeature) => {
    if (!supercluster || !cluster.properties.cluster_id || !mapRef.current) return;
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.properties.cluster_id) + 1,
      20,
    );
    mapRef.current.flyTo({
      center: cluster.geometry.coordinates as [number, number],
      zoom: expansionZoom,
    });
  };

  // Handle clicking a single marker
  const toggleMarkerClick = (point: PointFeature) => {
    if (point.properties.organisation) {
      if (member?.ExtKey === point.properties.extKey) {
        // Unselect if already selected
        setMember(null);
      } else {
        // Select the clicked marker
        setMember(point.properties.organisation);
      }
    } else {
      console.error('Organisation data is missing or null');
    }
  };

  const getBoxCoordinates = (): LngLatBoundsLike => {
    const minLat = Math.min(...organisations.map((o) => +o.Latitude));
    const maxLat = Math.max(...organisations.map((o) => +o.Latitude));
    const minLng = Math.min(...organisations.map((o) => +o.Longitude));
    const maxLng = Math.max(...organisations.map((o) => +o.Longitude));
    return [minLng, minLat, maxLng, maxLat];
  };

  /**
   * Adjusts map viewport based on filtered organizations and selected member
   */
  useEffect(() => {
    if (member) {
      mapRef.current?.flyTo({
        center: [member.Longitude, member.Latitude],
        zoom: 15,
      });
      return;
    } else if (coordinates) {
      mapRef.current?.flyTo({
        center: [coordinates.longitude, coordinates.latitude],
        zoom: 11,
      });
      return;
    } else if (organisations && organisations.length > 0) {
      mapRef.current?.fitBounds(getBoxCoordinates(), { padding: 100, maxZoom: 10 });
      return;
    }
    mapRef.current?.flyTo({ center: DEFAULT_CENTER, zoom: 7 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, organisations, member]);

  return (
    <MapContainer $visible={isSearchPopupVisible}>
      <MapBox
        ref={mapRef}
        onLoad={handleMapLoad}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_KEY}
        initialViewState={viewport}
        onMoveEnd={handleViewportChange}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/streets-v9">
        {/* Navigation controls only for web*/}
        {!isSmallScreen && (
          <>
            <NavigationControl />
            <GeolocateControl />
          </>
        )}
        {points.map((point, index) => {
          if (point.properties.cluster) {
            return (
              <ClusterMarker
                key={`cluster-${index}`}
                point={point}
                onClusterClick={handleClusterClick}
              />
            );
          }
          const [longitude, latitude] = point.geometry.coordinates;
          return (
            <MapMarker
              key={`marker-${index}`}
              latitude={latitude}
              longitude={longitude}
              onClick={() => toggleMarkerClick(point)}
              isSelected={member?.ExtKey === point.properties.extKey}
            />
          );
        })}
      </MapBox>
    </MapContainer>
  );
};
